<template>
  <div class="home">
    <link rel="preconnect" href="https://fonts.gstatic.com">
    <link href="https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet">

    <h1><img alt="Vue logo" src="../assets/logo.svg"><span class="logo-text">Odo</span></h1>
    <a id="get-odo" href="https://github.com/louis1001/odo/releases/latest">Get Odo</a>

    <section id="little-desc">
      <span>A simple, light-weight, interpreted programming language.</span>
    </section>

    <main>
      <section id="features">
        <div class="feature" id="you-know">
          <h2 class="feature-title">You know it</h2>
          <p class="feature-text">It's syntax is simple, familiar, and closely related to widely used languages. You will feel right at home</p>
        </div>
        <div class="feature" id="new-friends">
          <h2 class="feature-title">New friends</h2>
          <p class="feature-text">Odo does away with legacy artifacts, and introduces new, useful features. The best of both worlds.</p>
        </div>
        <div class="feature" id="polyglot">
          <h2 class="feature-title">Polyglot</h2>
          <p class="feature-text">The spanish odo implementation makes it so much easier to learn than any other typical language.</p>
        </div>
        <div class="feature" id="">
          <h2 class="feature-title">Getting better</h2>
          <p class="feature-text">Odo gets updated constantly, with improvements and optimization. If you think you can help, come join us!</p>
        </div>
      </section>
    </main>

    <section id="github-link">
      <a href="https://github.com/louis1001/odo">View on Github <img src="../assets/github.svg" alt="GH"></a>
    </section>

  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {}
}
</script>

<style scoped>
.home {
  font-family: 'Noto Sans', sans-serif;
  margin: 0;
  padding: 0 10px;
}

.home .logo-text {
  font-size: 90px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}

.home h1 {
  margin: 60px;
}

.home h1 img {
  max-width: 128px;
  vertical-align: middle;
}

#get-odo {
  display: inline-block;
  margin-bottom: 20px;
  padding: 5px 10px;
  border-radius: 3px;
  border: 1px solid gray;
}

#little-desc {
  text-align: right;
  font-size: 30px;
  font-weight: 400;
  font-style: italic;
  position: absolute;
  top: 100px;
  right: 50px;
  max-width: 500px;
}

#features {
  margin: 0 40px;
  max-width: 1200px;
}

.feature {
  margin: 0 25px;
  font-size: 1.4em;
  font-weight: 100;
  font-style: italic;
  text-align: justify;
  max-width: 400px;
  display: inline-block;
}

.feature .feature-title {
  color: rgb(85, 76, 76);
  text-align: center;
  margin-bottom: 0;
  font-weight: normal;
}

.feature .feature-text {
  margin-top: 15px;
}

a {
  color: unset;
  text-decoration: none;
}

#github-link {
  font-size: 40px;
  text-align: right;
  font-weight: bold;
}

#github-link img {
  max-height: 1em;
}

#github-link:hover {
  text-decoration: underline;
}

#github-link a:visited {
  color: unset;
}

@media only screen and (max-width: 1220px) {
  #get-odo {
    margin-top: 20px;
  }
  
  #features .feature {
    font-size: 1.2em;
  }

  #little-desc {
    position: relative;
    margin-left: auto;
    width: auto;
    top: 0;
    left: 0;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .home h1 {
    max-width: 100vw;
    margin: 10px;
  }

  .home h1 img {
    max-width: 100px;
  }

  .home .logo-text {
    font-size: 60px;
  }

  #github-link {
    margin-top: 30px;
    font-size: 1.6em;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1018px) {
  #get-odo {
    margin-top: 0;
  }

  #features {
    text-align: center;
  }

  #features .feature {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 400px) {
  .home .logo-text {
    font-size: 40px;
    margin-left: 10px;
  }
  .home h1 img {
    max-width: 60px;
  }

  .home h1 {
    margin: 30px 0;
  }

  #little-desc {
    font-size: 20px;
  }

  #github-link {
    margin-top: 100px;
    font-size: 30px;
  }
}

@media only screen and (max-width: 320px) {
  #features {
    margin: 0;
  }
  #github-link {
    font-size: 20px;
  }
}

</style>